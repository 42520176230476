import React, { useState, useEffect, useContext } from 'react';
import { ThemeContext } from "ToolboxUtils/web/context/context";
import isMobileView from 'ToolboxUtils/web/is-mobile-view';
import PropTypes from 'prop-types';
import { ButtonWrapper } from './button.styles';

const Button = props => {
  const theme = useContext(ThemeContext).state;
  const [isMobile, setIsMobile] = useState(null);
  useEffect(() => {
    setIsMobile(isMobileView());
  }, [isMobile]);
  return (
    <ButtonWrapper
      theme={theme}
      onClick={() => props.onClick && props.onClick()}
      reverseIconText={props.reverseIconText}
      isMobile={isMobile}
      disabled={props.disabled}
    >
      {props.children}
      {props.icon && <span className={props.icon}></span>}
    </ButtonWrapper>
  )
};

Button.propTypes = {
  type: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired,
  reverseIconText: PropTypes.bool,
  icon: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
}

export default Button;
