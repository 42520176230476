import styled from 'styled-components';

export const ResetPasswordWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${props => props.viewHeight};
  .block {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #f9f9f9;
    padding: 2rem 2rem 1rem;
    border: 1px solid #e8e8e8;
    width: ${props => (props.isMobile === true ? '' : '500px')};
    margin: auto;
    margin: ${props => (props.isMobile === true ? '1rem' : 'auto')};
    .title {
      color: ${props => { return props.theme.color.primaryColor }};
    }
    .form {
      position: relative;
      display: flex;
      align-items: stretch;
      flex-direction: column;
      margin-top: 1rem;
      .inputs {
        display: flex;
        flex-direction: column;
        input:not(:first-child) {
          margin-top: 1rem;
        }
      }
      .error {
        margin: 0 24px;
        margin-top: 1rem;
        font-size: ${props => `${13*props.theme.font.primaryFont.scaling/100}px`};
        text-align: center;
        font-weight: ${props => parseInt(props.theme.font.primaryFont.weight)+300};
      }
      .buttons-container {
        display: flex;
        justify-content: space-evenly;
        margin-top: 1rem;
      }
      input {
        margin: 0 24px;
        width: 300px;
        border: 1px solid #d6d2d2;
        border-radius: 3px;
        padding: 4px;
      }
    }
  }
`;
