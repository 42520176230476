import React, { useState, useEffect, useContext } from 'react';
import { ThemeContext, WhiteLabelContext } from "ToolboxUtils/web/context/context";
import PropTypes from 'prop-types';
import sha1 from 'sha1';
import isMobileView from "ToolboxUtils/web/is-mobile-view";
import Text from 'ToolboxComponents/commons/texts/text/text';
import Button from 'ToolboxComponents/commons/buttons/button/button';

import { ResetPasswordWrapper } from 'ToolboxComponents/webapp/pages/reset-password-view/reset-password-view.styles';

import { PASSWORD_MIN_LENGTH } from 'ToolboxParams/globals';

const ResetPasswordView = props => {
  const theme = useContext(ThemeContext).state;
  const whiteLabel = useContext(WhiteLabelContext).state;

  const { isError, isPasswordUpdated } = props;

  const [isMobile, setIsMobile] = useState(null);
  useEffect(() => {
    setIsMobile(isMobileView());
  }, [isMobile]);

  const [viewHeight, setViewHeight] = useState('0px');
  useEffect(() => {
    setTimeout(function(){
      setViewHeight(`${window.innerHeight - document.getElementById('main-nav').offsetHeight}px`);
    }, 100);
  }, []);

  const [newPassword, setNewPassword] = useState();
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState();
  const [errorMessage, setErrorMessage] = useState();
  useEffect(() => {
    if (newPassword && newPasswordConfirmation && newPassword !== newPasswordConfirmation) {
      setErrorMessage(whiteLabel.textualContent.page.resetPassword.passwordMismatch);
    } else {
      setErrorMessage(null);
    }
  }, [newPassword,newPasswordConfirmation]);

  const submit = event => {
    event.preventDefault();
    if (newPassword.length < PASSWORD_MIN_LENGTH) {
      setErrorMessage(whiteLabel.textualContent.page.resetPassword.passwordMinLength);
    } else {
      props.onSubmit(sha1(newPassword));
    }
  }

  return (
    <ResetPasswordWrapper theme={theme} isMobile={isMobile} viewHeight={viewHeight}>
      <div className='block'>
        {isError && !isPasswordUpdated &&
          <>
            <h3 className='title'>
              <Text path={`page.resetPassword.wrong`} />
            </h3>
            <Text path={`page.resetPassword.help`} />
          </>
        }
        {isPasswordUpdated &&
          <>
            <h3 className='title'>
              <Text path={`page.resetPassword.updated`} />
            </h3>
            <Text path={`page.resetPassword.signin`} />
          </>
        }
        {!isError && !isPasswordUpdated &&
          <>
            <h3 className='title'>
              <Text path={`page.resetPassword.edit`} />
            </h3>
            <form className='form' onSubmit={(event) => submit(event)}>
              <div className='inputs'>
                <input
                  id='newPassword'
                  name='newPassword'
                  type='password'
                  placeholder={whiteLabel.textualContent.page.resetPassword.enterPassword}
                  onChange={(event) => setNewPassword(event.target.value)}
                />
                <input
                  id='newPasswordConfirmation'
                  name='newPasswordConfirmation'
                  type='password'
                  placeholder= {whiteLabel.textualContent.page.resetPassword.repeatPassword}
                  onChange={(event) => setNewPasswordConfirmation(event.target.value)}
                />
              </div>
              {errorMessage &&
                <div className='error'>
                  {errorMessage}
                </div>
              }
              <div className='buttons-container'>
                <Button type='submit' disabled={errorMessage ? true : false}>
                  {whiteLabel.textualContent.page.resetPassword.confirm}
                </Button>
              </div>
            </form>
          </>
        }
      </div>
    </ResetPasswordWrapper>
  );
}

ResetPasswordView.propTypes = {
  isError: PropTypes.bool.isRequired,
  isPasswordUpdated: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired
}

export default ResetPasswordView;
