import styled from 'styled-components';

const ButtonWrapper = styled.button`
  display: flex;
  justify-content: ${props => { return props.minWidth ? 'space-between' : 'center' }};
  align-items: center;
  flex-direction: ${props => { return props.reverseIconText ? 'row-reverse' : 'row' }};
  .adn {
    margin-left: ${props => { return props.reverseIconText ? '' : '1vw' }};
    margin-right: ${props => { return props.reverseIconText ? '1vw' : '1vw' }};
  }
  background: white;
  border: ${props => { return `2px solid ${props.theme.color.primaryColor}` }};
  color: ${props => { return props.theme.color.primaryColor }};
  font-family: ${props => { return props.theme.font.primaryFont }};
  border-radius: 25px;
  font-weight: ${props => parseInt(props.theme.font.primaryFont.weight)+300};
  padding: 10px 20px;
  transition: all 0.3s ease-in-out;
  width: ${props => (props.isMobile === true ? '100%' : '')};
  min-width: ${props => (props.isMobile === true ? '' : '350px')};
  &:hover {
    background: ${props => { return props.theme.color.primaryColor }};
    color: white;
  }
  &:disabled, &:disabled:hover {
    border: 2px solid grey;
    color: grey;
    background: white;
  }
`;

export {ButtonWrapper};
